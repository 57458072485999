import React from 'react';
import SEO from '../components/SEO';

import Article from '../components/Articles/TOU/index';

export default function ({}) {
  return (
    <>
      <SEO
        title={'Time of Use Plans'}
        description={
          'Is your fixed rate electric plan costing you more money than you expected?  Making the switch to a Time of Use plan might help you start seeing real savings on your monthly bill.'
        }
        brand={'sc'}
      />

      <Article />
    </>
  );
}
