import React, { useState } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Parallax, ParallaxProvider } from 'react-scroll-parallax';

import * as webSocketActions from '../../../redux/actions/webSocketActions';

import TOC from '../../../components/TOC';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SavingsIcon from '@mui/icons-material/Savings';
import ZipCodeField from '../ZipCodeField';
import SCLandingWrapper from '../../SCLandingWrapper';
import FindPlansLink from '../FindPlansLink';
import FeaturedOfferElectric from '../../OfferGrid/FeaturedOfferElectric';

import '../index.css';
import './index.css';

function Article({ siteConfig, serviceAddress }) {
  return (
    <div className="article-container">
      <ZipCodeField mobile buttonLabel={'Time of Use Plans'} />
      <h1>
        <div>Do Time of Use Plans Really Work?</div>
      </h1>
      <div className="page-container text-container tou">
        <h5 className="est-read-time">
          Published 03/20/2023 | Read time {'~ '}2 min
        </h5>
        <div className="page">
          <div className="inner">
            <TOC>
              <ZipCodeField
                intro={
                  'Quickly find a Time of Use electric plan for your home:'
                }
              />
            </TOC>
            <div className="article-text">
              <h2>Introduction</h2>

              <p>
                As energy consumers, we all want to find the best plan that
                suits our lifestyle and budget. In Texas, one of the more
                popular plans is the Time of Use plan. This type of plan allows
                for free or cheaper rates during "off-peak hours", meaning you
                may be able to adjust your usage habits to find some real
                savings.
              </p>

              <div className="parallax-image">
                <ParallaxProvider>
                  <Parallax speed={-10}>
                    <AccessTimeIcon className="top-icon" />
                  </Parallax>
                </ParallaxProvider>
              </div>

              <h2>What is a Time of Use Plan?</h2>
              <p>
                A Time of Use plan is a type of electricity pricing plan that
                charges different rates based on the time of day. Typically,
                this plan will have three different rates: peak, off-peak, and
                super off-peak. Peak hours are the busiest times of day when
                energy demand is at its highest, and the price per kilowatt-hour
                (kWh) is the highest.{' '}
              </p>
              <p>
                Off-peak hours are typically the quietest times of day when
                energy demand is low, and the price per kWh is lower than peak
                hours. Super off-peak hours are usually the overnight hours, and
                the price per kWh is the lowest.
              </p>

              <h2>How Does a Time of Use Plan Work?</h2>
              <p>
                A Time of Use plan works by encouraging consumers to shift their
                energy consumption to off-peak or super off-peak hours when the
                price per kWh is the lowest.{' '}
              </p>
              <p>
                For example, if you use electricity to charge your electric
                vehicle (EV), you can schedule the charging to occur during
                off-peak or super off-peak hours to save money.{' '}
              </p>
              <p>
                Similarly, you can schedule your dishwasher or washing machine
                to run during off-peak or super off-peak hours to save money on
                energy bills.
              </p>
              <h2>Advantages of a Time of Use Plan</h2>
              <p>
                The biggest advantage of a Time of Use plan is the ability to
                save money on electricity by shifting your energy consumption to
                off-peak or super off-peak hours. This pricing structure
                encourages you to use energy when it is cheapest and helps
                reduce overall energy demand during peak hours.
              </p>
              <p>
                Time of Use plans are also a great fit for those who have
                predictable energy consumption patterns. If you know that you
                tend to use more electricity during certain times of the day or
                week, you can plan your energy consumption accordingly and save
                money on your electric bill.
              </p>
              <h2>Disadvantages of a Time of Use Plan</h2>
              <p>
                The biggest challenge to benefiting from a Time of Use plan is
                having to adjust your electricity use to fit the pricing
                structure. For example, if you are used to doing your laundry
                during peak hours, you may need to adjust your routine to do it
                during off-peak or super off-peak hours to save money.{' '}
              </p>
              <p>
                If you are not careful to shift your electricity use to the
                off-peak timeframe, you may end up paying more than you would
                have with something like a{' '}
                <a href="/fixed-rates" className="link">
                  fixed rate electric plan
                </a>
                .
              </p>
              <div className="parallax-image">
                <ParallaxProvider>
                  <Parallax speed={-10}>
                    <SavingsIcon className="bottom-icon" />
                  </Parallax>
                </ParallaxProvider>
              </div>
              <h2>Is a Time of Use Plan Right for You?</h2>
              <p>
                If you are not sure if a Time of Use plan is right for you, try
                comparing the plan options available here at {siteConfig.brand}.{' '}
                {siteConfig.brand} offers a range of tools and resources to help
                you make an informed decision, including a{' '}
                <a href="/" className="link">
                  rate comparison tool
                </a>{' '}
                and a{' '}
                <a href="/" className="link">
                  seasonal usage estimator
                </a>
                .
              </p>

              <FindPlansLink planType={'Time of Use'} />
            </div>
          </div>
        </div>
      </div>

      <SCLandingWrapper
        city={'dallas'}
        noHeader
        preFilter={{ rateType: { ['Time of Use']: true } }}
        preFilterLabel="Time of Use"
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    siteConfig: state.commonData.siteConfig,
    serviceAddress: state.serviceAddress
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Article);
